import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import PlainHeader from "components/ui/base/PlainHeader";
import { graphql } from "gatsby";
import { Alternative } from "app-types/alternative";
import AlternativeGrid from "components/ui/base/AlternativeGrid";
import AlternativeCard from "components/ui/base/AlternativeGrid/AlternativeCard";
import { getAlternativePath } from "utils/path";

interface TemplatesPageProps {
  data: {
    alternatives: {
      nodes: Alternative[];
    };
  };
  location: Location;
}

export default ({ data, location }: TemplatesPageProps) => {
  const { t } = useTranslation();
  const alternatives = data.alternatives.nodes;

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.alternatives.title")}
        description={t("pages.alternatives.description")}
        image={{
          relativePath: "meta/alternatives.jpg",
          alt: t("pages.alternatives.title"),
        }}
      />
      <PlainHeader
        title={t("pages.alternatives.title")}
        description={t("pages.alternatives.description")}
        maxWidth={900}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Alternatives",
            path: "/alternatives/",
          },
        ]}
      />

      <AlternativeGrid
        data={alternatives}
        component={(alternative) => (
          <AlternativeCard
            title={alternative.name}
            id={`${alternative.name}-alternative`}
            description={alternative.excerpt}
            path={getAlternativePath(alternative.slug)}
            logo={alternative.logo?.localFile.childImageSharp.gatsbyImageData}
          />
        )}
      />
    </GlobalWrapper>
  );
};

export const alternativesPageQuery = graphql`
  query {
    alternatives: allContentfulAlternative(
      filter: { node_locale: { eq: "en" } }
    ) {
      nodes {
        id
        slug
        name
        excerpt
        metaDescription
        metaImage {
          alt: title
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        testimonialQuote
        testimonialName
        node_locale
        testimonialImage {
          alt: title
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        alternativeTable {
          feature
          customerly
          alternative
        }
        faqElements {
          question
          answer
        }
        exclusiveFeatures {
          title
          description
          slug
        }
        ctaButtonLink
        ctaButtonText
        ctaText
        logo {
          alt: title
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        blogArticleUrl
        ctaImage {
          alt: title
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
