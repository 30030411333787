import React, { FunctionComponent } from "react";
import Heading from "components/core/Typography/Heading";
import { UnderlinedLink } from "styles/global.style";
import SafeLink from "components/core/SafeLink";
import Icon from "components/core/Icon";
import {
  AlternativeDetailsContainer,
  AlternativeLogo,
  StyledAlternativeCard,
} from "./index.style";
import { useTranslation } from "react-i18next";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { navigate } from "gatsby";

interface AlternativeCardProps {
  id: string;
  path: string;
  title: string;
  description: string;
  logo?: IGatsbyImageData;
}

const AlternativeCard: FunctionComponent<AlternativeCardProps> = ({
  id,
  path,
  logo,
  title,
  description,
}) => {
  const { t } = useTranslation();

  return (
    <StyledAlternativeCard
      key={id}
      onClick={() => {
        navigate(path);
      }}
    >
      {logo && <AlternativeLogo alt={title} image={logo} />}
      <AlternativeDetailsContainer>
        <Heading level={3}>{title}</Heading>
        <p>{description}</p>
        <UnderlinedLink as={SafeLink} to={path}>
          {t("components.ui.base.AlternativeCard.discover")}
          <Icon type={"arrow-right"} size={16} />
        </UnderlinedLink>
      </AlternativeDetailsContainer>
    </StyledAlternativeCard>
  );
};

export default AlternativeCard;
