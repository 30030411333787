import React, { ReactElement } from "react";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { MediumComponentContainer, PageContainer } from "styles/global.style";
import {
  AlternativeCol,
  AlternativeGridDescription,
  AlternativeGridTitle,
  StyledAlternativeGrid,
} from "./index.style";

interface AlternativeGridProps<T> {
  id?: string;
  title?: string;
  titleLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  description?: string;
  centered?: boolean;
  centeredTitle?: boolean;
  alternativeColors?: boolean;
  data: T[];
  component: (data: T) => ReactElement;
}

const AlternativeGrid = <T extends unknown>(props: AlternativeGridProps<T>) => {
  const titleLevel = props.titleLevel ? props.titleLevel : 2;

  return (
    <StyledAlternativeGrid
      id={props.id}
      centered={props.centered}
      centeredTitle={props.centeredTitle}
      alternativeColors={props.alternativeColors}
    >
      <MediumComponentContainer>
        <PageContainer>
          <Container>
            {props.title && (
              <Row>
                <Col>
                  <AlternativeGridTitle level={titleLevel}>
                    {props.title}
                  </AlternativeGridTitle>
                  {props.description && (
                    <AlternativeGridDescription>
                      {props.description}
                    </AlternativeGridDescription>
                  )}
                </Col>
              </Row>
            )}
            <Row justifyContent={props.centered ? "center" : "start"}>
              {props.data.map((element, index) => (
                <AlternativeCol key={index} lg={4} md={6} sm={12}>
                  {props.component(element)}
                </AlternativeCol>
              ))}
            </Row>
          </Container>
        </PageContainer>
      </MediumComponentContainer>
    </StyledAlternativeGrid>
  );
};

export default AlternativeGrid;
