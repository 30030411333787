import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

const StyledAlternativeCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
`;

const AlternativeLogo = styled(GatsbyImage)`
  border: 1px solid ${(props) => props.theme.baseLighten75Color};
  border-radius: 16px;
`;

const AlternativeDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  h3 {
    font-size: 22px;
  }

  p {
    font-size: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
  }
`;

export { StyledAlternativeCard, AlternativeLogo, AlternativeDetailsContainer };
