import styled, { css } from "styled-components";
import { StyledHeading } from "components/core/Typography/Heading/index.style";
import { Col, media } from "styled-bootstrap-grid";
import { MediumComponentContainer } from "styles/global.style";
import Heading from "components/core/Typography/Heading";

export interface StyledAlternativeGridProps {
  centered?: boolean;
  centeredTitle?: boolean;
  alternativeColors?: boolean;
}

const AlternativeGridTitle = styled(Heading)``;

const AlternativeGridDescription = styled.p``;

const AlternativeCol = styled(Col)`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  height: 400px;
`;

const StyledAlternativeGrid = styled.section<StyledAlternativeGridProps>`
  ${StyledHeading} {
    font-weight: 600;
    line-height: 1.2;
    color: ${(props) => props.theme.titleColor};
    margin-bottom: 0.3em;
    margin-top: 0.75em;
  }

  ${(props) =>
    props.centeredTitle &&
    css`
      ${AlternativeGridTitle}, ${AlternativeGridDescription} {
        text-align: center;
      }
    `}

  ${(props) =>
    props.alternativeColors &&
    css`
      ${AlternativeGridTitle}, ${AlternativeGridDescription} {
        text-align: center;
        margin-top: 0;
      }

      ${MediumComponentContainer} {
        background: ${props.theme.baseLighten95Color};
        padding: 80px 0;

        ${media.md`
          padding: 96px 0;
        `}
      }
    `}

  ${(props) =>
    props.centered &&
    css`
      ${StyledHeading}, ${AlternativeGridTitle}, ${AlternativeGridDescription}, p {
        text-align: center;
      }

      ${AlternativeCol} {
        display: flex;
        align-items: center;
      }
    `}
`;

export {
  StyledAlternativeGrid,
  AlternativeCol,
  AlternativeGridTitle,
  AlternativeGridDescription,
};
